import React, { Fragment, ReactNode, useEffect } from "react"
import PropTypes from "prop-types"
import { graphql, Link } from "gatsby"
import { useBreadcrumb } from "gatsby-plugin-breadcrumb"
import { findIconDefinition } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"


// Components
import SEO from "../components/Seo"
import LayoutBase from "../components/Layout"
import PageHeader from "../components/PageHeader"
import { InformationCategoryTag } from "../components/InformationList"


// 登録している Font Awesome Icon を取得する
const falArrowCircleRight = findIconDefinition({ prefix: "fal", iconName: "arrow-circle-right" })
const falArrowCircleLeft = findIconDefinition({ prefix: "fal", iconName: "arrow-circle-left" })


// Style
import "../styles/templates/information-post.scss"


/**
 * Information post Template
 * インフォメーション詳細ページ
 *
 */
interface IInformationContext {
    id: string,
    date: string,
    category: string,
    title: string,
    body: any,
    prevUrl: string,
    prevTitle: string,
    nextUrl: string,
    nextTitle: string,
}

interface IInformationDefaultProps {
    data: any,
    path?: string,
    pageContext?: IInformationContext,
    location?: object,
    children?: any,
}

const InformationPost = (props: IInformationDefaultProps) => {
    let _date = ""
    let _category = ""
    let _title = ""
    let _body = ""
    let _pageId = ""
    let _prevUrl = ""
    let _prevTitle = ""
    let _nextUrl = ""
    let _nextTitle = ""
    if (props.pageContext !== undefined) {
        _date = props.pageContext.date
        _category = props.pageContext.category
        _title = props.pageContext.title
        _body = props.pageContext.body
        _pageId = props.pageContext.id
        _prevUrl = props.pageContext.prevUrl
        _prevTitle = props.pageContext.prevTitle
        _nextUrl = props.pageContext.nextUrl
        _nextTitle = props.pageContext.nextTitle
    }
    let bodyEl
    if (typeof window !== "undefined") {
        bodyEl = document.createElement("div")
        bodyEl.innerHTML = _body
    }

    // ヘッダ部背景画像
    const headerImage = props.data.information_post_pageHeader_backgroundImage.childImageSharp.fluid

    // 日付を日本の記述にする
    const date = String(_date).split("-")
    const year = Number(date[0])
    const month = Number(date[1])
    const day = Number(date[2])
    const dateJp = `${year}年 ${month}月 ${day}日`

    // 前と次ページの短縮タイトル
    function truncateTitle(str: string, len: number) {
        return str.length <= len ? str : (str.substr(0, len) + "...")
    }

    const truncateLength = 20
    const prevShortTitle = truncateTitle(_prevTitle, truncateLength)
    const nextShortTitle = truncateTitle(_nextTitle, truncateLength)

    // ページの設定
    const pageId = _pageId
    const pageHeaderTitle = "インフォメーション"
    const pageTitle = truncateTitle(_title, 6)
    const location = props.location
    const { crumbs } = useBreadcrumb({
        location: location,
        crumbLabel: pageTitle,
    })


    return (
        <Fragment>
            <SEO pageTitle={pageTitle}/>
            <LayoutBase
                pageId={pageId}
                crumbs={crumbs}
                location={location}
            >
                <PageHeader
                    title={pageHeaderTitle}
                    backgroundImage={headerImage}
                    indicators={true}
                />
                <div className="common-content-layout common-content-format">
                    <div className="grid-container common-content-layout__section">
                        <div className="grid-x">
                            <div className="cell">
                                <div className="information-post-subtitle">
                                    <InformationCategoryTag category={_category}/><span>{dateJp}</span>
                                </div>
                                <h2>{_title}</h2>
                            </div>
                            <div className="cell">
                                <div dangerouslySetInnerHTML={{ __html: _body }}/>
                            </div>
                        </div>
                        <div className="grid-x grid-margin-x align-middle information-post-pagination">
                            <div
                                className="cell small-12 small-order-1 medium-4 medium-order-1 text-left information-post-pagination__prev">
                                <PostPagination
                                    url={_nextUrl}
                                    emptyMessage="最初のページ"
                                    emptyStyle={{ color: "#999" }}
                                >
                                    <FontAwesomeIcon
                                        icon={falArrowCircleLeft}
                                        size="1x"
                                        style={{ marginRight: "0.5rem" }}
                                    />
                                    {nextShortTitle}
                                </PostPagination>
                            </div>
                            <div className="cell small-12 small-order-3 medium-4 medium-order-2 text-center">
                                <Link to="/information/" className="button" style={{ marginBottom: "0" }}>一覧へ戻る</Link>
                            </div>
                            <div
                                className="cell small-12 small-order-2 medium-4 medium-order-3 text-right information-post-pagination__next">
                                <PostPagination
                                    url={_prevUrl}
                                    emptyMessage="最後のページ"
                                    emptyStyle={{ color: "#999" }}
                                >
                                    {prevShortTitle}
                                    <FontAwesomeIcon
                                        icon={falArrowCircleRight}
                                        size="1x"
                                        style={{ marginLeft: "0.5rem" }}
                                    />
                                </PostPagination>
                            </div>
                        </div>
                    </div>
                </div>
            </LayoutBase>
        </Fragment>
    )
}


export default InformationPost


/**
 * 詳細ページ用ページネーションリンク
 *
 * @param {string} url - リンク先のURL
 * @param {string} className? - スタイルクラス
 * @param {object} style? - インラインスタイル
 * @param {string} emptyMessage? - url が空("")のときに表示するテキスト
 * @param {string} emptyClassName? - url が空("")のときのスタイルクラス
 * @param {object} emptyStyle? - url が空("")のときのインラインスタイル
 * @param {ReactNode} children
 * @return {*} React Element - urlが空("")のときは null を返す
 */
interface IPostPaginationDefaultProps {
    url: string,
    emptyMessage?: string,
    className?: string,
    style?: object,
    emptyClassName?: string,
    emptyStyle?: object,
    children: ReactNode,
}

const PostPagination = (props: IPostPaginationDefaultProps) => {
    if (props.url != "") {
        return (
            <Link to={props.url} className={props.className} style={props.style}>
                {props.children}
            </Link>
        )
    } else {
        return (
            <div className={props.emptyClassName} style={props.emptyStyle}>{props.emptyMessage}</div>
        )
    }
}

export const informationPostQuery = graphql`
    query {
        information_post_pageHeader_backgroundImage: file(relativePath: {eq: "company_pageHeader_backgroundImage.jpg"}) {
            childImageSharp {
                fluid(maxWidth: 2500, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                    presentationWidth
                }
            }
        },
    }
`
